import { Stethoscope } from "@phosphor-icons/react";
import { motion } from "framer-motion";

export const DonationBanner = () => (
  <motion.a
    href="https://www.medicalportfolio.ai"
    target="_blank"
    whileHover={{ height: 48 }}
    initial={{ opacity: 0, y: -50, height: 32 }}
    animate={{ opacity: 1, y: 0, transition: { duration: 0.3 } }}
    className="hidden w-screen items-center justify-center gap-x-2 bg-zinc-800 text-xs font-bold leading-relaxed text-zinc-50 lg:flex"
  >
    <Stethoscope weight="bold" size={14} className="shrink-0" />
    <span>
      Looking for a job in the medical field? Visit our Medical Jobs Platform for the latest listings and opportunities!
    </span>
  </motion.a>
);